import React from 'react';
import Background from "../images/BG.jpg";
const PDF_PATH = process.env.PUBLIC_URL + '/FrontEnd-Developer.pdf';
// import FrontEndDeveloperPDF from "FrontEnd-Developer.pdf"; 
export default function Home() {

  const downloadFile = (url) => {
    const fileName = url.split("/").pop();
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => console.error('Download error', error));
  }

  return (
    <div>
      <section id="colorlib-hero" className="js-fullheight" data-section="home">
        <div style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '100vh',
        }} role="img" aria-label="Background Image 1">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
              <div className="slider-text-inner js-fullheight">
                <div className="desc1">
                  <h1>Hi! <br />I'm Arvind</h1>
                  <p>
                    <button className="btn btn-primary btn-learn"  onClick={() => {
                      downloadFile(PDF_PATH);
                    }}>Download CV <i className="icon-download4"></i></button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
