import React from 'react';
import { Link } from 'react-router-dom';

export default function Experience() {
  return (
    <section id='colorlib-experience' className="colorlib-experience" data-section="experience">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
								data-animate-effect="fadeInLeft">
								<span className="heading-meta">Experience</span>
								<h2 className="colorlib-heading animate-box">Work Experience</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="timeline-centered">
									<article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
										<div className="timeline-entry-inner">

											<div className="timeline-icon color-1">
												<i className="icon-pen2"></i>
											</div>

											<div className="timeline-label">
												<h2><Link to="#">Designqandy Senior Front-end Developer </Link> <span>April
														2022 - Present</span></h2>
												<p>At Designqandy, I serve as a Senior Front-end Developer, specializing
													in Laravel websites. My primary responsibilities include editing
													existing websites, working on the CRM UI, creating new websites, and
													updating them regularly. I design website UI using Bootstrap and
													incorporate various jQuery effects to enhance user experience.</p>
											</div>
										</div>
									</article>


									<article className="timeline-entry animate-box" data-animate-effect="fadeInRight">
										<div className="timeline-entry-inner">
											<div className="timeline-icon color-2">
												<i className="icon-pen2"></i>
											</div>
											<div className="timeline-label">
												<h2><Link to="#">FTV.LTD Senior Front-end Developer </Link>
													<span>July 2020 - March 2022</span>
												</h2>
												<p>As a Senior Front-end Developer at FTV.LTD, I was responsible for
													editing Laravel websites and focusing on the CRM UI. I played a key
													role in creating new websites and ensuring regular updates. My
													expertise lies in designing website UI using Bootstrap and
													implementing various jQuery effects to create dynamic and
													interactive web pages.</p>
											</div>
										</div>
									</article>

									<article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
										<div className="timeline-entry-inner">
											<div className="timeline-icon color-3">
												<i className="icon-pen2"></i>
											</div>
											<div className="timeline-label">
												<h2><Link to="#">SOTC Pvt. Ltd Senior Web Developer
													</Link> <span>March 2016 - June 2020</span></h2>
												<p>During my tenure at SOTC Pvt. Ltd, I served as a Senior Web Designer,
													where I was instrumental in creating and updating websites
													regularly. I designed website UI using Bootstrap and incorporated
													different jQuery effects to enhance the visual appeal and
													functionality. Additionally, I designed HTML5 animated banners using
													Google Web Designer Tool and executed various digital advertising
													campaigns in collaboration with internal stakeholders.

												</p>
											</div>
										</div>
									</article>

									<article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
										<div className="timeline-entry-inner">
											<div className="timeline-icon color-4">
												<i className="icon-pen2"></i>
											</div>
											<div className="timeline-label">
												<h2><Link to="#">Xeco Marketing Pvt. Ltd Web Designer
													</Link> <span>June 2013 - March 2016</span></h2>
												<p>At Xeco Marketing Pvt. Ltd, I worked as a Web Designer, focusing on
													updating websites with new banners and innovative formats. I created
													new pages with innovative designs and implemented new jQuery effects
													for templates. I also developed new microsites to support various
													marketing initiatives.</p>
											</div>
										</div>
									</article>

									<article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
										<div className="timeline-entry-inner">
											<div className="timeline-icon color-5">
												<i className="icon-pen2"></i>
											</div>
											<div className="timeline-label">
												<h2><Link to="#">Edit Institution Teacher for HTML, CSS, XML, Dreamweaver
													</Link> <span>March 2012 - June 2013</span></h2>
												<p>As a teacher at Edit Institution, I imparted technical knowledge on
													software tools like Photoshop, Flash, jQuery, Dreamweaver, and
													Hemlocks. My role involved educating students on HTML, CSS, XML, and
													Dreamweaver, ensuring a solid foundation in web development and
													design principles.</p>
											</div>
										</div>
									</article>

									<article className="timeline-entry begin animate-box"
										data-animate-effect="fadeInBottom">
										<div className="timeline-entry-inner">
											<div className="timeline-icon color-none">
											</div>
										</div>
									</article>
								</div>
							</div>
						</div>
					</div>
				</section>
  )
}
