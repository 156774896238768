import React from 'react';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <section id="colorlib-about" className="colorlib-about" data-section="about">
    <div className="colorlib-narrow-content">
        <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm " data-animate-effect="fadeInLeft">
                    <div className="col-md-12">
                        <div className="about-desc">
                            <span className="heading-meta">About Us</span>
                            <h2 className="colorlib-heading">Who Am I?</h2>
                            <p><strong>Hi I'm Arvind Dige</strong> I have 12+ Years of experience (25
                                months at Design Qandy) to the table. He has a bachelors degree in
                                Commerce (2011). With expertise in front-end development (HTML5, CSS3,
                                Bootstrap, JavaScript, jQuery, React.js, Node.js, Adobe Suite, API
                                Development,
                                Google web designer tool, MySQL, MongoDB), he
                                excels in crafting user-friendly and interactive web applications.
                                Possesses a strong understanding of the entire
                                web development process, from design and development to deployment and
                                SEO principles. </p>
                            <p>Also leverages
                                proficiency in Photoshop, Figma, and CMS systems for a well-rounded
                                skillset. I am actively collaborates with
                                designers and back-end developers to ensure usability, maintain
                                websites, and optimize applications for performance.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3" data-animate-effect="fadeInLeft">
                        <div className="services color-1">
                            <span className="icon2"><i className="icon-bulb"></i></span>
                            <h3>Graphic Design</h3>
                        </div>
                    </div>
                    <div className="col-md-3" data-animate-effect="fadeInRight">
                        <div className="services color-2">
                            <span className="icon2"><i className="icon-globe-outline"></i></span>
                            <h3>Web Design</h3>
                        </div>
                    </div>
                    <div className="col-md-3" data-animate-effect="fadeInTop">
                        <div className="services color-3">
                            <span className="icon2"><i className="icon-data"></i></span>
                            <h3>Development</h3>
                        </div>
                    </div>
                    <div className="col-md-3" data-animate-effect="fadeInBottom">
                        <div className="services color-4">
                            <span className="icon2"><i className="icon-phone3"></i></span>
                            <h3>Application</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12" data-animate-effect="fadeInLeft">
                        <div className="hire">
                            <h2>I am happy to know you <br/>that 300+ projects done sucessfully!</h2>
                            <Link to="mailto:arvinddige369@gmail.com" className="btn-hire">Hire me</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
