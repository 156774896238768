import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

export default function Contact() {
	const form = useRef();
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_2ow4x2e', 'template_m18qubh', form.current, '6Cjrmy8B1cBJBWzwt')
      .then(
        () => {
          console.log('SUCCESS!');
          setMessage('Your message has been sent successfully!');
		  form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          setMessage('Failed to send the message, please try again later.');
        }
      );
  };

  return (
    <section id='colorlib-contact' className="colorlib-contact" data-section="contact">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
								data-animate-effect="fadeInLeft">
								<span className="heading-meta">Get in Touch</span>
								<h2 className="colorlib-heading">Contact</h2>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-5 col-md-7">
								<div className="colorlib-feature colorlib-feature-sm animate-box"
									data-animate-effect="fadeInLeft">
									<div className="colorlib-icon">
										<i className="icon-globe-outline"></i>
									</div>
									<div className="colorlib-text">
										<p><Link to="#">arvinddige369@gmail.com</Link></p>
									</div>
								</div>

								<div className="colorlib-feature colorlib-feature-sm animate-box"
									data-animate-effect="fadeInLeft">
									<div className="colorlib-icon">
										<i className="icon-map"></i>
									</div>
									<div className="colorlib-text">
										<p>Thane West</p>
									</div>
								</div>

								<div className="colorlib-feature colorlib-feature-sm animate-box"
									data-animate-effect="fadeInLeft">
									<div className="colorlib-icon">
										<i className="icon-phone"></i>
									</div>
									<div className="colorlib-text">
										<p><Link to="tel://">+91 9773 191 369</Link></p>
									</div>
								</div>
							</div>
							<div className="col-lg-7 col-md-12 col-md-push-1">
								<div className="row">
									<div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box"
										data-animate-effect="fadeInRight">
										<form id="contact-form"  ref={form} onSubmit={sendEmail} method="post">
											<div className="form-group">
												<input type="text" name="user_name" className="form-control" placeholder="Name" />
											</div>
											<div className="form-group">
												<input type="email" name="user_email" className="form-control" placeholder="Email" />
											</div>
											<div className="form-group">
												<input type="text" name="subject" className="form-control"
													placeholder="Subject" />
											</div>
											<div className="form-group">
												<textarea name="message" cols="30" rows="7" className="form-control"
													placeholder="Message"></textarea>
											</div>
											<div className="form-group">
												<input type="submit" className="btn btn-primary btn-send-message"
													value="Send Message" />
											</div>
											{message && <p>{message}</p>}  {/* Display message here */}
										</form>
									</div>

								</div>
							</div>
						</div>
					</div>
				</section>
  )
}
