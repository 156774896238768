import './App.css';
import './component/sideNav';
import './css/animate.css';
import './css/icomoon.css';
// import './css/bootstrap.css';
import './css/flexslider.css';
import './css/owl.carousel.min.css';
import './css/owl.theme.default.min.css';
import './css/style.css';

import 'bootstrap/dist/js/bootstrap.bundle'
import SideBar from './component/SideBar';
import Home from './component/Home';
import About from './component/About';
import Services from './component/Services';
import Skills from './component/Skills';
import Education from './component/Education';
import Experience from './component/Experience';
import Work from './component/Work';
import Contact from './component/Contact';

function App() {
  return (
    <div className="App">
      <div id="colorlib-page">
        <div className="container-wrap">
          <SideBar />
          <div id="colorlib-main">
            <Home />
            <About />
            <Services />
            <Skills/>
            <Education/>
            <Experience/>
            <Work/>
           <Contact/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
