import React from 'react';

export default function Services() {
  return (
    <section id='colorlib-services' className="colorlib-services" data-section="services">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-lg-6 col-md-12 col-md-offset-3 col-md-pull-3"
								data-animate-effect="fadeInLeft">
								<span className="heading-meta">What I do?</span>
								<h2 className="colorlib-heading">Here are some of my expertise</h2>
							</div>
						</div>
						<div className="row row-pt-md">
							<div className="col-lg-4 col-md-6 text-center">
								<div className="services color-1">
									<span className="icon">
										<i className="icon-bulb"></i>
									</span>
									<div className="desc">
										<h3>JavaScript</h3>
										<p>Proficient in leveraging the power of JavaScript to create dynamic and
											interactive user interfaces. Skilled in vanilla JavaScript as well as modern
											frameworks like React.js.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="services color-2">
									<span className="icon">
										<i className="icon-data"></i>
									</span>
									<div className="desc">
										<h3>jQuery</h3>
										<p>Experienced in using jQuery to simplify DOM manipulation, handle events, and
											streamline AJAX requests, resulting in more efficient and maintainable
											codebases.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="services color-3">
									<span className="icon">
										<i className="icon-phone3"></i>
									</span>
									<div className="desc">
										<h3>React.js</h3>
										<p>Adept at building scalable and reusable components in React.js, utilizing
											state management, hooks, and lifecycle methods to deliver high-performance
											web applications.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="services color-4">
									<span className="icon">
										<i className="icon-layers2"></i>
									</span>
									<div className="desc">
										<h3>Node.js</h3>
										<p>Knowledgeable in backend development with Node.js, proficient in creating
											RESTful APIs and leveraging the Express.js framework for building robust
											server-side applications.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="services color-5">
									<span className="icon">
										<i className="icon-data"></i>
									</span>
									<div className="desc">
										<h3>API Development</h3>
										<p>Experienced in designing, developing, and testing RESTful APIs, employing
											best practices for authentication, data validation, and error handling to
											ensure secure and reliable communication between client and server.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 text-center">
								<div className="services color-6">
									<span className="icon">
										<i className="icon-phone3"></i>
									</span>
									<div className="desc">
										<h3>HTML5 & CSS3</h3>
										<p>Skilled in utilizing the latest features of HTML5 and CSS3 to create
											semantic, accessible, and visually appealing web pages, with a strong
											emphasis on responsive design and cross-browser compatibility.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
  )
}
