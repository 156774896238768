import React, { useState, useEffect } from 'react';
import image1 from '../images/photo.jpg';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

export default function SideBar() {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('#colorlib-hero');
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };

  const handleLinkClick = (hash) => {
    setActiveSection(hash);
    setIsOpen(false);
  };

  useEffect(() => {
    setActiveSection(location.hash || '#colorlib-hero');
  }, [location]);

  return (
    <div>
      <Link
        to=""
        className="js-colorlib-nav-toggle colorlib-nav-toggle"
        data-toggle="collapse"
        data-target="#navbar"
        aria-expanded="false"
        aria-controls="navbar"
        onClick={toggleMenu}
      >
        <i className={`icon ${isOpen ? 'icon-close' : 'icon-menu'}`}></i>
      </Link>
      <div className={`aside1 ${isOpen ? 'is-open' : ''}`}>
        <div id="colorlib-aside" role="complementary" className="border js-fullheight">
          <div className="text-center">
            <div
              className="author-img"
              style={{ backgroundImage: `url(${image1})` }}
            ></div>
            <h1 id="colorlib-logo">
              <Link to="/#colorlib-hero">Arvind Dige</Link>
            </h1>
            <span className="position">
              <Link to="#">Front End Developer</Link> in India
            </span>
          </div>
          <nav id="colorlib-main-menu" role="navigation" className="navbar">
            <div id="navbar" className={`collapse ${isOpen ? 'show' : ''}`}>
              <ul>
                <li className={activeSection === '#colorlib-hero' ? 'active' : ''}>
                  <Link to="#colorlib-hero" data-nav-section="home" onClick={() => handleLinkClick('#colorlib-hero')}>Home</Link>
                </li>
                <li className={activeSection === '#colorlib-about' ? 'active' : ''}>
                  <Link to="#colorlib-about" data-nav-section="about" onClick={() => handleLinkClick('#colorlib-about')}>About</Link>
                </li>
                <li className={activeSection === '#colorlib-services' ? 'active' : ''}>
                  <Link to="#colorlib-services" data-nav-section="services" onClick={() => handleLinkClick('#colorlib-services')}>Services</Link>
                </li>
                <li className={activeSection === '#colorlib-skills' ? 'active' : ''}>
                  <Link to="#colorlib-skills" data-nav-section="skills" onClick={() => handleLinkClick('#colorlib-skills')}>Skills</Link>
                </li>
                <li className={activeSection === '#colorlib-education' ? 'active' : ''}>
                  <Link to="#colorlib-education" data-nav-section="education" onClick={() => handleLinkClick('#colorlib-education')}>Education</Link>
                </li>
                <li className={activeSection === '#colorlib-experience' ? 'active' : ''}>
                  <Link to="#colorlib-experience" data-nav-section="experience" onClick={() => handleLinkClick('#colorlib-experience')}>Experience</Link>
                </li>
                <li className={activeSection === '#colorlib-work' ? 'active' : ''}>
                  <Link to="#colorlib-work" data-nav-section="work" onClick={() => handleLinkClick('#colorlib-work')}>Work</Link>
                </li>
                <li className={activeSection === '#colorlib-contact' ? 'active' : ''}>
                  <Link to="#colorlib-contact" data-nav-section="contact" onClick={() => handleLinkClick('#colorlib-contact')}>Contact</Link>
                </li>
              </ul>
            </div>
          </nav>

          <div className="colorlib-footer">
            <p>
              <small>
                &copy; {new Date().getFullYear()} All rights reserved. Made with
                <i className="icon-heart" aria-hidden="true"></i> by
                <Link to="https://arvinddige.com" target="_blank">Arvind Dige</Link>
                <span>Linkedin <Link to="https://www.linkedin.com/in/arvind-dige-Developer/" target="_blank">My Profile</Link></span>
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
