import React, { useState } from 'react';
import img1 from '../images/mailer1.jpg';
import img2 from '../images/mailer2.jpg';
import img3 from '../images/mailer3.jpg';
import img4 from '../images/mailer4.jpg';
import img5 from '../images/mailer5.jpg';
import img6 from '../images/mailer6.jpg';
import img7 from '../images/mailer7.jpg';
import img8 from '../images/mailer8.jpg';
import img9 from '../images/mailer9.jpg';
import img10 from '../images/mailer10.jpg';
import img11 from '../images/mailer11.jpg';
import img12 from '../images/mailer12.jpg';
import img13 from '../images/mailer13.jpg';
import img14 from '../images/mailer14.jpg';
import img15 from '../images/banner1.jpg';
import img16 from '../images/banner12.jpg';
import img17 from '../images/banner9.jpg';
import img18 from '../images/banner3.jpg';
import img19 from '../images/banner4.jpg';
import img20 from '../images/banner5.jpg';
import img21 from '../images/banner6.jpg';
import img22 from '../images/banner7.jpg';
import img23 from '../images/banner8.jpg';
import img24 from '../images/banner18.jpg';
import img25 from '../images/banner17.jpg';
import img26 from '../images/Quarry.jpg';
import img27 from '../images/design-qandy-web.jpg';
import img28 from '../images/qandystoris-web.jpg';
import img29 from '../images/utsav-web.jpg';
import img30 from '../images/sotc-web.jpg';
import img31 from '../images/sotctours-web.jpg';
import img32 from '../images/instaquot-web.jpg';
import img33 from '../images/style-test-opn1.jpg';
import img34 from '../images/style-test-opn2.jpg';
import img35 from '../images/style-test-opn3.jpg';
import img36 from '../images/app-design.jpg';
import img37 from '../images/info-page.jpg';

export default function Work() {
    const [activeTab, setActiveTab] = useState('webDesign');

    const renderContent = () => {
        switch (activeTab) {
            case 'graphicDesign':
                return (
                    <div>
                        <h5>Graphic Design Content</h5>
                        <div className="row">
                            {[img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25].map((image, index) => (
                                <div key={index} className="col-lg-3 col-md-3 col-12">
                                    <div className="mailer-banner"><img src={image} alt={`Graphic Design ${index + 1}`} /></div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 'webDesign':
                return (
                    <div>
                        <h5>Web Development</h5>
                        <div className="row">
                            {[img26, img27, img28, img29, img30, img31, img32].map((image, index) => (
                                <div key={index} className="col-lg-4 col-md-4 col-12">
                                    <div className="quarry-web"><img src={image} alt={`Web Design ${index + 1}`} /></div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 'software':
                return (
                    <div>
                        <h5>CRM</h5>
                        <div className="row">
                            {[img33, img34, img35].map((image, index) => (
                                <div key={index} className="col-lg-12 col-md-12 col-12">
                                    <div className="quarry-web"><img src={image} alt={`CRM ${index + 1}`} /></div>
                                </div>
                            ))}
                        </div>
                   </div>
                );
            case 'apps':
                return (
                    <div>
                        <h5>Apps Content</h5>
                        <div className="row">
                            {[img36, img37].map((image, index) => (
                                <div key={index} className={`col-lg-${index === 0 ? '12' : '4'} col-md-${index === 0 ? '12' : '4'} col-12`}>
                                    <div className="quarry-web"><img src={image} alt={`App ${index + 1}`} /></div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <section id="colorlib-work" className="colorlib-work" data-section="work">
            <div className="colorlib-narrow-content">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                        <span className="heading-meta">My Work</span>
                        <h2 className="colorlib-heading animate-box">Recent Work</h2>
                    </div>
                </div>
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                    <div className="col-md-12">
                        <ul className="tabs">
                            <li className={`tab ${activeTab === 'graphicDesign' ? 'active' : ''}`} onClick={() => setActiveTab('graphicDesign')}>Graphic Design</li>
                            <li className={`tab ${activeTab === 'webDesign' ? 'active' : ''}`} onClick={() => setActiveTab('webDesign')}>Web Development</li>
                            <li className={`tab ${activeTab === 'software' ? 'active' : ''}`} onClick={() => setActiveTab('software')}>CRM</li>
                            <li className={`tab ${activeTab === 'apps' ? 'active' : ''}`} onClick={() => setActiveTab('apps')}>Apps</li>
                        </ul>
                    </div>
                </div>
                <div className="tab-content">
                    {renderContent()}
                </div>
                
            </div>
        </section>
    );
}
