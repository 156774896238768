import React from 'react';
import { Accordion } from 'react-bootstrap';

export default function Education() {
  return (
    <section id='colorlib-education' className="colorlib-education" data-section="education">
      <div className="colorlib-narrow-content">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
            data-animate-effect="fadeInLeft">
            <span className="heading-meta">Education</span>
            <h2 className="colorlib-heading animate-box">Education</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Bachelor Degree of Commerce (B.COM)</Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <p>Karnataka University, 2011. Completed a comprehensive Bachelor of Commerce program from Karnataka University, where I gained a solid foundation in business principles, accounting, finance, and management.</p>
                    </div>
                    <div className="col-md-6">
                      <p>The rigorous curriculum equipped me with analytical, problem-solving, and decision-making skills essential for a successful career in the IT sector.</p>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Higher Secondary Certificate (HSC)</Accordion.Header>
                <Accordion.Body>
                  <p>Delhi University, 2008</p>
                  <p>Successfully completed my Higher Secondary Certificate (HSC) from Delhi University, focusing on subjects that laid the groundwork for my academic pursuits and provided me with a strong academic background essential for higher education and professional growth.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Secondary School Certificate (SSC)</Accordion.Header>
                <Accordion.Body>
                  <p>Maharashtra State Board, 2005</p>
                  <p>Attained the Secondary School Certificate (SSC) from Maharashtra State Board, where I developed foundational knowledge and skills across various subjects, fostering a passion for learning and setting the stage for my academic journey ahead.</p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Digital Animation & Web Designing Course</Accordion.Header>
                <Accordion.Body>
                  <p>EDIT Institution, Thane, 2009</p>
                  <p>Completed an intensive course in Digital Animation and Web Designing from EDIT Institution in Thane. This specialized training enabled me to acquire hands-on experience in multimedia design, web development, and digital animation techniques, equipping me with the technical expertise required to create engaging and interactive digital content.</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}
